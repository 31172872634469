export default {
	cinema: {
		address: 'ул. Советская, 36',
		place: null,
		phoneNumbers: ['+7 (49645) 5-85-11'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/public157715434',
		twitter: null,
		odnoklassniki: 'https://ok.ru/group/54615378821346',
		youtube: null,
		app: {
			appstore: 'https://apps.apple.com/ru/app/кинокасса-билеты-в-кино/id1493004732',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.kinokassa&hl=ru',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CKUgj2Op',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
