import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '74, 20, 140',
		'primary-dark': '18, 0, 94',
		'accent': '244, 13, 1',
		'accent-plus': '255, 255, 255',
	},
});
